import { formatDistance } from 'date-fns'

export const convertMillisToHumanReadableInterval = (millis: number = 0): [string, number] => {
  const duration: { [key: string]: number; } = {};
  const units = [
    { label: 'millis', mod: 1000 },
    { label: 'seconds', mod: 60 },
    { label: 'minutes', mod: 60 },
    { label: 'hours', mod: 24 },
    { label: 'days', mod: 7 },
    { label: 'weeks', mod: 4 },
    { label: 'months', mod: 12 },
  ];
  units.forEach((unit) => {
    millis = (millis - (duration[unit.label] = (millis % unit.mod))) / unit.mod;
  });

  return (Object.entries(duration).reverse().find(([key]) => duration[key]) || ['millis', 0]);
}

export const convertHumanReadableIntervalToMillis = (
  intervalValue: number,
  intervalType: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'
): number => {
  const units = [
    { label: 'seconds', mod: 1000 },
    { label: 'minutes', mod: 60 * 1000 },
    { label: 'hours', mod: 60 * 60 * 1000 },
    { label: 'days', mod: 24 * 60 * 60 * 1000 },
    { label: 'weeks', mod: 7 * 24 * 60 * 60 * 1000 },
    { label: 'months', mod: 30 * 24 * 60 * 60 * 1000 },
    { label: 'years', mod: 365 * 24 * 60 * 60 * 1000 },
  ];
  const singleUnitValue = units.find((unit) => unit.label === intervalType);

  if (!singleUnitValue) {
    throw new Error(`Can not convert date for unsupported period: ${intervalType}`);
  }

  return singleUnitValue.mod * intervalValue;
}

export enum WeekDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export enum DateUnits {
  second = 'second',
  seconds = 'seconds',
  minute = 'minute',
  minutes = 'minutes',
  hour = 'hour',
  hours = 'hours',
  day = 'day',
  days = 'days',
  week = 'week',
  weeks = 'weeks',
  month = 'month',
  months = 'months',
  year = 'year',
  years = 'years',
}

export type DateUnit =
  'second' |
  'seconds' |
  'minute' |
  'minutes' |
  'hour' |
  'hours' |
  'day' |
  'days' |
  'week' |
  'weeks' |
  'month' |
  'months' |
  'year' |
  'years';
export class DateUtil {
  private date!: Date;
  private set currentValue(date: Date) {
    if (!DateUtil.isDateValid(date)) {
      throw Error(`Invalid date`);
    }

    this.date = date;
  }
  private get currentValue() {
    return this.date;
  }

  private tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  get currentYear() {
    return this.currentValue.getFullYear();
  }

  get currentMonth() {
    return this.currentValue.getMonth();
  }

  constructor(initialDate: string | number | Date = new Date()) {
    if (
      typeof initialDate === 'string' ||
      typeof initialDate === 'number' ||
      initialDate instanceof Date
    ) {
      this.currentValue = new Date(initialDate);
    } else {
      this.currentValue = new Date();
    }
  }

  static isWorkingDay(date: Date): boolean {
    return ![WeekDay.SATURDAY, WeekDay.SUNDAY].includes(date.getDay());
  }

  static isDayOff(date: Date): boolean {
    return [WeekDay.SATURDAY, WeekDay.SUNDAY].includes(date.getDay());
  }

  static getCurrentWeekNumber() {
    const currentDate = new Date();
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    const days = Math.floor((currentDate.getTime() - startDate.getTime()) / this.getDayMillis());

    return Math.ceil(days / 7);
  }

  static getDayOfYear(date: Date) {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date.getTime() - start.getTime();
    const dayOfYear = Math.floor(diff / DateUtil.getMillis(1, 'day'));
    return dayOfYear;
  }

  static getWeekBoundaries(weekNumber: number, year: number = new Date().getFullYear(), offset: number = 0) {
    const currentDate = new Date();
    const currentWeekNumber = DateUtil.getCurrentWeekNumber();
    const diff = (currentWeekNumber - weekNumber) * this.getDayMillis(7);
    const currentWeekFirstDayNumber = currentDate.getDate() - currentDate.getDay();
    const currentWeekLastDayNumber = currentWeekFirstDayNumber + 6;
    const currentWeekFirstDay = new Date(currentDate.setDate(currentWeekFirstDayNumber));
    const currentWeekLastDay = new Date(currentDate.setDate(currentWeekLastDayNumber));

    const startDate = new Date(currentWeekFirstDay.getTime() - diff);
    const endDate = new Date(currentWeekLastDay.getTime() - diff);
    const dayOffset = offset * this.getDayMillis();

    return { start: new Date(startDate.getTime() + dayOffset), end: new Date(endDate.getTime() + dayOffset) }
  }

  static isDateValid(date: unknown): boolean {
    const dateObject = typeof date === 'string' ? new Date(date) : date;
    return dateObject instanceof Date && !isNaN(Number(dateObject));
  }

  timezone(timezone?: string) {
    this.tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this;
  }

  static formatDistance(fromDate: Date, toDate: Date, opts?: { addSuffix: boolean; }): string {
    return formatDistance(fromDate, toDate, { addSuffix: Boolean(opts?.addSuffix) });
  }

  startOfDay() {
    // eslint-disable-next-line new-cap
    const dateParts = Intl.DateTimeFormat('en-US', {
      timeZone: this.tz,
      hourCycle: 'h23',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).formatToParts(this.currentValue);

    const hour = parseInt(dateParts.find(i => i.type === 'hour')?.value || '0', 10);
    const minute = parseInt(dateParts.find(i => i.type === 'minute')?.value || '0', 10);
    const second = parseInt(dateParts.find(i => i.type === 'second')?.value || '0', 10);

    this.currentValue = new Date(
      1000 *
      Math.floor(
        (this.currentValue.getTime() -
          DateUtil.getHourMillis(hour) -
          DateUtil.getMinuteMillis(minute) -
          DateUtil.getSecondMillis(second)) /
        DateUtil.getSecondMillis()
      )
    );

    return this;
  }

  endOfDay() {
    this.currentValue = new Date(
      this.startOfDay().toDate().getTime() + (DateUtil.getDayMillis() - 1)
    );

    return this;
  }

  startOfWeek() {
    const dayOfWeek = this.currentValue.getDay() + 1;

    if (7 - dayOfWeek === 0) {
      return this.startOfDay().subtract(6, 'days');
    }

    return this.startOfDay().add(7 - dayOfWeek, 'day').subtract(6, 'days');
  }

  endOfWeek() {
    const dayOfWeek = this.currentValue.getDay() + 1;

    if (7 - dayOfWeek === 0) {
      return this.endOfDay();
    }

    return this.endOfDay().add(7 - dayOfWeek, 'days');
  }

  startOfMonth(n: number = 0) {
    this.currentValue = new Date(this.currentValue.setMonth(this.currentMonth - n, 1));
    this.startOfDay();

    return this;
  }

  endOfMonth(n: number = 0) {
    this.currentValue = new Date(this.currentValue.getFullYear(), (this.currentMonth + 1) + n, 0);
    this.endOfDay();

    return this;
  }

  startOfYear() {
    this.currentValue = new Date(this.currentValue.setFullYear(this.currentYear, 0, 1));
    return this.startOfDay();
  }

  endOfYear() {
    this.currentValue = new Date(this.currentValue.setFullYear(this.currentYear, 11, 31));
    return this.endOfDay();
  }

  subtract(amount: number, unit: DateUnit) {
    this.currentValue = new Date(this.currentValue.getTime() - DateUtil.getMillis(amount, unit));
    return this;
  }

  add(amount: number, unit: DateUnit) {
    this.currentValue = new Date(this.currentValue.getTime() + DateUtil.getMillis(amount, unit));
    return this;
  }

  toDate() {
    return this.currentValue;
  }

  toISOString() {
    return this.currentValue.toISOString();
  }

  format(pattern = '%yyyy/%MM/%dd - %HH:%mm', timeZone?: string) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dayOfWeekNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const date = timeZone
      ? new Date(this.currentValue.toLocaleString('en-US', { timeZone }))
      : new Date(this.currentValue);

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const miliseconds = date.getTime();
    const h = hour % 12;
    const hh = this.twoDigitPad(h);
    const HH = this.twoDigitPad(hour);
    const mm = this.twoDigitPad(minute);
    const ss = this.twoDigitPad(second);
    const aaa = hour < 12 ? 'AM' : 'PM';
    const EEEE = dayOfWeekNames[date.getDay()];
    const EEE = EEEE.slice(0, 3);
    const dd = this.twoDigitPad(day);
    const M = month + 1;
    const MM = this.twoDigitPad(M);
    const MMMM = monthNames[month];
    const MMM = MMMM.slice(0, 3);
    const yyyy = String(year);
    const yy = yyyy.slice(2, 2);

    return pattern
      .replace('%hh', hh)
      .replace('%h', String(h))
      .replace('%HH', HH)
      .replace('%H', String(hour))
      .replace('%mm', mm)
      .replace('%m', String(minute))
      .replace('%MMMM', MMMM)
      .replace('%MMM', MMM)
      .replace('%MM', MM)
      .replace('%M', String(M))
      .replace('%ss', ss)
      .replace('%s', String(second))
      .replace('%S', String(miliseconds))
      .replace('%dd', dd)
      .replace('%d', String(day))
      .replace('%DD', dd)
      .replace('%D', String(day))
      .replace('%EEEE', EEEE)
      .replace('%EEE', EEE)
      .replace('%yyyy', yyyy)
      .replace('%yy', yy)
      .replace('%YYYY', yyyy)
      .replace('%YY', yy)
      .replace('%aaa', aaa);
  }

  calculateNumberOfYears() {
    const millis = DateUtil.getMillis(1, 'year');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  calculateNumberOfMonths() {
    const millis = DateUtil.getMillis(1, 'month');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  calculateNumberOfWeeks() {
    const millis = DateUtil.getMillis(1, 'week');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  calculateNumberOfDays() {
    const millis = DateUtil.getMillis(1, 'day');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  calculateNumberOfHours() {
    const millis = DateUtil.getMillis(1, 'hour');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  calculateNumberOfMinutes() {
    const millis = DateUtil.getMillis(1, 'minute');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  calculateNumberOfSeconds() {
    const millis = DateUtil.getMillis(1, 'second');
    const diff = Date.now() - this.currentValue.getTime();
    return Math.floor(diff / millis);
  }

  toAge() {
    let age = 0;

    age = this.calculateNumberOfYears();
    if (age) {
      return `${age}y`;
    }

    age = this.calculateNumberOfMonths();
    if (age) {
      return `${age}mo`;
    }

    age = this.calculateNumberOfWeeks();
    if (age) {
      return `${age}w`;
    }

    age = this.calculateNumberOfDays();
    if (age) {
      return `${age}d`;
    }

    age = this.calculateNumberOfHours();
    if (age) {
      return `${age}h`;
    }

    age = this.calculateNumberOfMinutes();
    if (age) {
      return `${age}m`;
    }

    age = this.calculateNumberOfSeconds();
    if (age) {
      return `${age}s`;
    }

    return 'less then a second';
  }

  static getMillis(amount: number, unit: DateUnit) {
    switch (unit) {
      case DateUnits.second:
      case DateUnits.seconds:
        return this.getSecondMillis(amount);

      case DateUnits.minute:
      case DateUnits.minutes:
        return this.getMinuteMillis(amount);

      case DateUnits.hour:
      case DateUnits.hours:
        return DateUtil.getHourMillis(amount);

      case DateUnits.day:
      case DateUnits.days:
        return DateUtil.getDayMillis(amount);

      case DateUnits.week:
      case DateUnits.weeks:
        return this.getWeekMillis(amount);

      case DateUnits.month:
      case DateUnits.months:
        return this.getMonthMillis(amount);

      case DateUnits.year:
      case DateUnits.years:
        return this.getYearMillis(amount);

      default:
        throw new Error(`[DateUtil] Unit ${unit} is not supported`);
    }
  }

  private static getSecondMillis(value: number = 1) {
    return value * 1000;
  }

  private static getMinuteMillis(value: number = 1) {
    return value * 60 * this.getSecondMillis();
  }

  private static getHourMillis(value: number = 1) {
    return value * 60 * this.getMinuteMillis();
  }

  private static getDayMillis(value: number = 1) {
    return value * 24 * this.getHourMillis();
  }

  private static getWeekMillis(value: number = 1) {
    return value * 7 * this.getDayMillis();
  }

  private static getMonthMillis(value: number = 1) {
    return value * 4 * this.getWeekMillis();
  }

  private static getYearMillis(value: number = 1) {
    return value * 12 * this.getMonthMillis();
  }

  private twoDigitPad(value: number): string {
    return String(value < 10 ? '0' + value : value);
  }

}

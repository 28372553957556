export class ArrayUtil {
  static hasDuplicates(list: any[] = []) {
    return new Set(list).size < list.length;
  }

  static removeElements<T = unknown>(list: T[] = [], elementsToRemove: T[] = []) {
    return list.filter(element => !elementsToRemove.includes(element));
  }

  static max(array: any[] = []) {
    ArrayUtil.checkIsArrayOfNumbers(array);

    return Math.max(...array);
  }

  static min(array: any[] = []) {
    ArrayUtil.checkIsArrayOfNumbers(array);

    return Math.min(...array);
  }

  static checkIsArrayOfNumbers(array: any[] = []) {
    if (!array.every(el => typeof el === 'number')) {
      throw Error(
        `Cannot calculate the sum of an array because some elements are not numbers.`
      );
    }
  }
}

import { FactoryItem, ItemImages, } from '@coremodels/item.model';
import { Client } from '@core/entities/clients';
import { Customer } from './customer.model';
import { Flyer } from './order-flyer.model';
import { OrderStatusValue } from './order-status.model';
import { OrderComment } from '@core/entities/order-comment';
import { OrderTag } from '@core/entities/order-tag';

export enum OrderAttribute {
  HasComments = 'has-comments',
  HasNotes = 'has-notes',
  MissingBatch = 'missing-batch',
}

export enum ShipByDateOption {
  delayed = 'delayed',
  today = 'today',
  upcoming = 'upcoming',
}

export enum OrderAggregationInclude {
  comments = 'comments',
}

interface OrderAggregationMetadata {
  totalComments: number;
  totalEvents: number;
}

interface ShippingOption {
  Carrier: String,
  ShipByDate: Date,
  ShippingCode: String,
}

export interface OrderBaseAggregation extends ShippingOption {
  BatchActive?: boolean;
  BatchName: string;
  Client?: Pick<Client, 'clientId' | 'storeName'>;
  ClientId: string;
  Channel?: string;
  ClientNote?: string;
  Comments?: OrderComment[];
  CustomerNotes: string;
  FactoryAlert: boolean;
  FactoryNote: string;
  Items: string[];
  OrderDate: string;
  OrderNumber: string;
  OrderStatus: OrderStatusValue;
  OrderTotal?: number;
  RushOrder: boolean;
  RushType: string;
  RushValue: string;
  ShippingOptions?: ShippingOption[],
  Tags?: OrderTag[];
  shippedAt: string;
  createdAt: string;
  id: string;
  metadata?: Partial<OrderAggregationMetadata>;
}
export interface OrderAggregation extends OrderBaseAggregation {
  FrameData?: unknown[];
  ItemsData: {
    LineItemID: string;
    ClientItemId?: string;
    ClientPrice: number;
    ProductType: string;
    Quantity: number;
    ProductCode: string;
    Size: string;
    FrameType?: string;
    Color: string;
    TotalFrameSize?: number;
    PinColor: string;
  }[];
}

export interface OrderDetailsAggregation extends OrderBaseAggregation {
  Allocation?: string;
  Customer: Partial<Customer>;
  FrameData?: unknown[];
  InternalNotes?: string[];
  Items: string[];
  ItemsData: ItemAggregation[];
  Options?: Option[];
  PackingSlipPrinted?: boolean;
  PackingSlipPrintedBy?: string;
  ShippingAmount?: number;
  ShippingLabelUrl?: string;
  ShippingPrice: number;
  TrackingNumber?: string;
  Flyers: Flyer[] | undefined;
}

export interface OrderAggregationFilter {
  endDate?: string;
  productType?: string;
  searchText?: string;
  startDate?: string;
  status?: string;
  triggerId?: string;
  attribute?: OrderAttribute;
}

export interface ItemBaseAggregation {
  FrameType: string;
  LineItemID: string;
  LineItemIndex: number;
  ProductType: string;
  Quantity: number;
  ProductCode: string;
  Color?: string;
  PinColor?: string;
  Name?: string;
  SignatureUrl?: string;
}

export interface Option {
  Type?: string;
  SubType?: string;
  Option?: string;
  Price?: number;
}

export interface ItemAggregation extends ItemBaseAggregation {
  Size: string;
  Depth: string;
  ClientItemId: string;
  FactoryItems: Pick<FactoryItem, 'ItemId' | 'ParentItemId' | 'ProductionState'>[];
  Location?: string[];
  ClientPrice: number;
  NumberOfParts: number;
  SKU: string;
  ImageUrl: string;
  Images: ItemImages;
  Options?: Option[];
  ShippingPrice: number;
}

